<template>
  <b-card>
    <g-form @submit="print">
      <b-row>
        <b-col md="6">
          <b-form-group>
            <g-field
              :value.sync="selectedItem.fromMonth"
              :dir="isRight ? 'rtl' : 'ltr'"
              label-text="month"
              field="select"
              name="fromMonth"
              rules="required"
              :options="months"
              label="arabicName"
              @change="
                (v) => {
                  updateToMonth(v);
                }
              "
            />
          </b-form-group>
        </b-col>
        <!-- from year -->
        <b-col md="6">
          <b-form-group>
            <g-field
              :value.sync="selectedItem.fromYear"
              :dir="isRight ? 'rtl' : 'ltr'"
              label-text="year"
              field="select"
              name="fromYear"
              :options="years"
              rules="required"
              label="arabicName"
              @change="
                (v) => {
                  updateToYear(v);
                }
              "
            />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col md="4">
          <b-form-group>
            <g-field
              :value.sync="selectedItem.employeeId"
              :dir="isRight ? 'rtl' : 'ltr'"
              label-text="employee"
              field="select"
              name="toEmployee"
              :options="employees"
              :label="isRight ? 'arabicName' : 'englishName'"
            />
          </b-form-group>
        </b-col>
        <!-- fromEmployee  -->
        <b-col md="4">
          <b-form-group>
            <g-field
              :value.sync="selectedItem.departmentId"
              :dir="isRight ? 'rtl' : 'ltr'"
              label-text="department"
              field="select"
              name="fromEmployee"
              :options="departments"
              :label="isRight ? 'arabicName' : 'englishName'"
            />
          </b-form-group>
        </b-col>
        <!-- fromDepartment  -->
        <b-col md="4">
          <b-form-group>
            <g-field
              :value.sync="selectedItem.divisionId"
              :dir="isRight ? 'rtl' : 'ltr'"
              label-text="division"
              field="select"
              name="fromDepartment"
              :options="divisions"
              :label="isRight ? 'arabicName' : 'englishName'"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <!-- review button -->
      <b-row>
        <b-col cols="12" class="d-flex justify-content-end">
          <b-button
            class="mx-1"
            variant="relief-primary"
            @click="print('total')"
          >
            <feather-icon class="mr-25" icon="ListIcon" />
            {{ $t('printTotal') }}
          </b-button>

          <b-button variant="outline-primary" @click="print('detail')">
            <feather-icon class="mr-25" icon="LoaderIcon" />
            {{ $t('printDetails') }}
          </b-button>
        </b-col>
      </b-row>
    </g-form>
  </b-card>
</template>
<script>
import reportMixin from '@/mixin/reportMixin';
import { months, years, reportParameters } from '@/libs/acl/Lookups';

export default {
  mixins: [reportMixin],
  props: {
    type: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      employees: [],
      departments: [],
      divisions: [],
      jobs: [],
      selectedItem: {
        type: null,
        departmentId: null,
        managementId: null,
        employeeId: null,
        fromMonth: null,
        toMonth: null,
        fromYear: null,
        toYear: null,
        branchName: '',
        departmentName: '',
        employeeName: '',
        ManagementName: '',
      },
      months: months,
      years: years,
      reportName: '',
    };
  },
  computed: {},
  beforeMount() {
    this.fiscalYearStart = this.currentYear.startDate;
    this.fiscalYearEnd = this.currentYear.endDate;
  },
  watch: {
    'selectedItem.employeeId'(employeeId) {
      this.get({ url: 'Employees', id: employeeId }).then((data) => {
        this.selectedItem.departmentId = data.departmentId;
        this.selectedItem.divisionId = data.divisionId;
      });
    },
  },
  mounted() {
    // this.selectedItem.fromHireDate = this.getDate(this.fiscalYearStart);
    // this.selectedItem.toHireDate = this.getDate(this.fiscalYearEnd);
    this.getData();
    this.getmanagement();
    this.getJob();
  },
  methods: {
    validateForm() {
      if (!this.selectedItem.fromMonth || !this.selectedItem.fromYear) {
        this.doneAlert({ text: this.$t('mustChooseMonthAndYearFirst'), type: 'warning', timer: 3000 });
        return false
      }
      return true
    },
    updateToMonth(item) {
      this.selectedItem.toMonth = item.id;
    },
    updateToYear(item) {
      this.selectedItem.toYear = item.id;
    },
    checkDate() {
      if (this.selectedItem.fromHireDate === null) {
        this.selectedItem.fromHireDate = this.getDate(this.fiscalYearStart);
      }
      if (this.selectedItem.toHireDate === null) {
        this.selectedItem.toHireDate = this.getDate(this.fiscalYearEnd);
      }
    },
    getmanagement() {
      this.get({ url: 'Divisions' }).then((data) => {
        this.divisions = data;
      });
    },
    getJob() {
      this.get({ url: 'Employees' }).then((data) => {
        this.employees = data;
      });
    },
    getData() {
      this.get({ url: 'Departments' }).then((data) => {
        this.departments = data;
      });
    },
    filterDataBaseOnId(source, compareTo) {
      return source.find((val) => val.id === compareTo)
    },
    preparePrametersForPrint() {
      const selectedEmployee = this.filterDataBaseOnId(this.employees, this.selectedItem.employeeId);
      const allEmployees = this.filterDataBaseOnId(reportParameters, 'allEmployees');
      this.selectedItem.employeeName = this.selectedItem.employeeId
                                     ? this.isRight ? selectedEmployee.arabicName : selectedEmployee.englishName
                                     : this.isRight ? allEmployees.arabicName : allEmployees.englishName;

      const selectedDepartment = this.filterDataBaseOnId(this.departments, this.selectedItem.departmentId);
      const allDepartments = this.filterDataBaseOnId(reportParameters, 'allDepartments');
      this.selectedItem.departmentName = this.selectedItem.departmentId
                                       ? this.isRight ? selectedDepartment.arabicName : selectedDepartment.englishName
                                       : this.isRight ? allDepartments.arabicName : allDepartments.englishName;

      const selectedDivision = this.filterDataBaseOnId(this.divisions, this.selectedItem.divisionId);
      const allDivisions = this.filterDataBaseOnId(reportParameters, 'allDivisions');
      this.selectedItem.divisionName = this.selectedItem.divisionId
                                     ? this.isRight ? selectedDivision.arabicName : selectedDivision.englishName
                                     : this.isRight ? allDivisions.arabicName : allDivisions.englishName;

      this.selectedItem.branchName = this.isRight
                                   ? this.currentBranch.arabicName
                                   : this.currentBranch.englishName;
    },
    getReportName(type) {
      switch (type) {
        case 'total':
          return this.isRight ? 'Payslip-ar' : 'Payslip-en';

        case 'detail':
          return this.isRight ? 'EmployeePayrolls-ar' : 'EmployeePayrolls-en';

        default:
          return '';
      }
    },
    print(type) {
      if (!this.validateForm()) return;
      this.checkDate();
      if (!this.checkForFiscalYearPerm(this.selectedItem.fromHireDate, this.selectedItem.toHireDate)) return;
      this.reportName = this.getReportName(type);
      this.preparePrametersForPrint();
      this.printReport(this.reportName, this.selectedItem);
    },
  },
};
</script>
