<template>
  <b-card>
    <g-form @submit="print">
      <b-row>
        <!-- from Month -->
        <b-col md="3">
          <b-form-group>
            <g-field
              :value.sync="selectedItem.fromMonth"
              :dir="isRight ? 'rtl' : 'ltr'"
              label-text="fromMonth"
              field="select"
              name="fromMonth"
              :options="months"
              label="arabicName"
              @change="
                (v) => {
                  updateToMonth(v);
                }
              "
            />
          </b-form-group>
        </b-col>

        <!-- to month -->
        <b-col md="3">
          <b-form-group>
            <g-field
              :value.sync="selectedItem.toMonth"
              :dir="isRight ? 'rtl' : 'ltr'"
              label-text="toMonth"
              field="select"
              name="toMonth"
              :options="months"
              label="arabicName"
            />
          </b-form-group>
        </b-col>

        <!-- from year -->
        <b-col md="3">
          <b-form-group>
            <g-field
              :value.sync="selectedItem.fromYear"
              :dir="isRight ? 'rtl' : 'ltr'"
              label-text="fromYear"
              field="select"
              name="fromYear"
              :options="years"
              label="arabicName"
              @change="
                (v) => {
                  updateToYear(v);
                }
              "
            />
          </b-form-group>
        </b-col>

        <!-- to year -->
        <b-col md="3">
          <b-form-group>
            <g-field
              :value.sync="selectedItem.toYear"
              :dir="isRight ? 'rtl' : 'ltr'"
              label-text="toYear"
              field="select"
              name="toYear"
              :options="years"
              label="arabicName"
            />
          </b-form-group>
        </b-col>

        <!-- employee  -->
        <b-col md="6">
          <b-form-group>
            <g-field
              :value.sync="selectedItem.employeeId"
              :dir="isRight ? 'rtl' : 'ltr'"
              label-text="employee"
              field="select"
              name="employee"
              :options="employees"
              :label="isRight ? 'arabicName' : 'englishName'"
            />
          </b-form-group>
        </b-col>

        <!-- management  -->
        <b-col md="6">
          <b-form-group>
            <g-field
              :value.sync="selectedItem.managementId"
              :dir="isRight ? 'rtl' : 'ltr'"
              label-text="department"
              field="select"
              name="fromEmployee"
              :options="managements"
              :label="isRight ? 'arabicName' : 'englishName'"
            />
          </b-form-group>
        </b-col>

        <!-- job -->
        <b-col md="6">
          <b-form-group>
            <g-field
              :value.sync="selectedItem.jobId"
              :dir="isRight ? 'rtl' : 'ltr'"
              label-text="job"
              field="select"
              name="toEmployee"
              :options="jobs"
              :label="isRight ? 'arabicName' : 'englishName'"
            />
          </b-form-group>
        </b-col>

        <!-- department  -->
        <b-col md="6">
          <b-form-group>
            <g-field
              :value.sync="selectedItem.departmentId"
              :dir="isRight ? 'rtl' : 'ltr'"
              label-text="division"
              field="select"
              name="fromDepartment"
              :options="departments"
              :label="isRight ? 'arabicName' : 'englishName'"
            />
          </b-form-group>
        </b-col>
      </b-row>

      <b-row>
        <b-col
          cols="12"
          class="d-flex justify-content-end"
        >
          <b-button
            type="submit"
            variant="relief-primary"
          >
            <feather-icon
              class="mr-50"
              icon="EyeIcon"
            />
            {{ $t('review') }}
          </b-button>
        </b-col>
      </b-row>
    </g-form>
  </b-card>
</template>
<script>
import reportMixin from '@/mixin/reportMixin';
import { months, years, reportParameters } from '@/libs/acl/Lookups';

export default {
  mixins: [reportMixin],
  props: {
    type: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      employees: [],
      departments: [],
      managements: [],
      jobs: [],
      selectedItem: {
        type: 0,
        employeeId: null,
        departmentId: null,
        managementId: null,
        jobId: null,
        fromMonth: null,
        toMonth: null,
        fromYear: null,
        toYear: null,
        branchName: '',
        departmentName: '',
        jobName: '',
        ManagementName: ''
      },
      months: months,
      years: years,
      reportName: '',
    };
  },
  computed: {},
  watch: {
    'selectedItem.employeeId'(employeeId) {
      this.get({ url: 'Employees', id: employeeId }).then((data) => {
          this.selectedItem.managementId = data.departmentId
          this.selectedItem.departmentId = data.divisionId
          this.selectedItem.jobId = data.jobId
        })
    }
  },
  beforeMount() {
    this.fiscalYearStart = this.currentYear.startDate;
    this.fiscalYearEnd = this.currentYear.endDate;
  },
  mounted() {
    this.selectedItem.fromHireDate = this.getDate(this.fiscalYearStart);
    this.selectedItem.toHireDate = this.getDate(this.fiscalYearEnd);
    this.getData();
    this.getmanagement();
    this.getJob();
    this.getEmployees();
  },
  methods: {
    updateToMonth(item) {
      this.selectedItem.toMonth = item.id;
    },
    updateToYear(item) {
      this.selectedItem.toYear = item.id;
    },
    checkDate() {
      if (this.selectedItem.fromHireDate === null) {
        this.selectedItem.fromHireDate = this.getDate(this.fiscalYearStart);
      }
      if (this.selectedItem.toHireDate === null) {
        this.selectedItem.toHireDate = this.getDate(this.fiscalYearEnd);
      }
    },
    getmanagement() {
      this.get({ url: 'Divisions' }).then((data) => {
        this.departments = data;
      });
    },
    getJob() {
      this.get({ url: 'Jobs' }).then((data) => {
        this.jobs = data;
      });
    },
    getEmployees() {
      this.get({ url: 'Employees' }).then((data) => {
        this.employees = data;
      });
    },
    getData() {
      this.get({ url: 'Departments' }).then((data) => {
        this.managements = data;
      });
    },
    preparePrametersForPrint() {
      // for benefit
      if (this.type.route === 'benefits-report') {
        this.selectedItem.type = 1;
        this.selectedItem.isAdvancePayment = false;
        this.reportName = this.isRight ? 'Benefits-ar' : 'Benefits-en'
      }
      // for deduction
      if (this.type.route === 'deductions-report') {
        this.selectedItem.type = 2;
        this.selectedItem.isAdvancePayment = false;
        this.reportName = this.isRight ? 'Deductions-ar' : 'Deductions-en';
      }
      // for advanced Payment
      if (this.type.route === 'advancedPayments-report') {
        this.selectedItem.type = 2;
        this.selectedItem.isAdvancePayment = true;
        this.reportName = this.isRight ? 'AdvancedPayments-ar' : 'AdvancedPayments-en';
      }
      if (this.selectedItem.employeeId) {
        this.selectedItem.employeeName = this.isRight
                                       ? this.employees.find((pr) => pr.id === this.selectedItem.employeeId).arabicName
                                       : this.employees.find((pr) => pr.id === this.selectedItem.employeeId).englishName
      } else {
        this.selectedItem.employeeName = this.isRight
                                       ? reportParameters.find((pr) => pr.id === 'allEmployees').arabicName
                                       : reportParameters.find((pr) => pr.id === 'allEmployees').englishName
      }
      if (this.selectedItem.departmentId) {
        this.selectedItem.departmentName = this.isRight
                                         ? this.departments.find((pr) => pr.id === this.selectedItem.departmentId).arabicName
                                         : this.departments.find((pr) => pr.id === this.selectedItem.departmentId).englishName;
      } else {
        this.selectedItem.departmentName = this.isRight
                                         ? reportParameters.find((pr) => pr.id === 'allDivisions').arabicName
                                         : reportParameters.find((pr) => pr.id === 'allDivisions').englishName
      }
      if (this.selectedItem.jobId) {
        this.selectedItem.jobName = this.isRight
                                  ? this.jobs.find((pr) => pr.id === this.selectedItem.jobId).arabicName
                                  : this.jobs.find((pr) => pr.id === this.selectedItem.jobId).englishName;
      } else {
        this.selectedItem.jobName = this.isRight
                                  ? reportParameters.find((pr) => pr.id === 'allJobs').arabicName
                                  : reportParameters.find((pr) => pr.id === 'allJobs').englishName
      }
      if (this.selectedItem.managementId) {
        this.selectedItem.ManagementName = this.isRight
                                         ? this.managements.find((pr) => pr.id === this.selectedItem.managementId).arabicName
                                         : this.managements.find((pr) => pr.id === this.selectedItem.managementId).englishName;
      } else {
        this.selectedItem.ManagementName = this.isRight
                                         ? reportParameters.find((pr) => pr.id === 'allDepartments').arabicName
                                         : reportParameters.find((pr) => pr.id === 'allDepartments').englishName
      }
      this.selectedItem.branchName = this.isRight
                                   ? this.currentBranch.arabicName
                                   : this.currentBranch.englishName;
    },
    print() {
      this.checkDate();
      if (!this.checkForFiscalYearPerm(this.selectedItem.fromHireDate, this.selectedItem.toHireDate)) return;
      this.preparePrametersForPrint();
      this.printReport(this.reportName, this.selectedItem);
    },
  },
};
</script>
