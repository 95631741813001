<template>
  <b-card>
    <g-form @submit="print">
      <b-row>
        <!-- fromDate  -->
        <b-col md="6">
          <g-picker
            :value.sync="selectedItem.fromHireDate"
            label-text="fromDate"
          />
        </b-col>
        <b-col md="6">
          <g-picker
            :value.sync="selectedItem.toHireDate"
            label-text="toDate"
          />
        </b-col>
      </b-row>
      <b-row>
        <b-col md="4">
            <b-form-group>
              <g-field
                :value.sync="selectedItem.employeeId"
                :dir="isRight ? 'rtl' : 'ltr'"
                label-text="employee"
                field="select"
                name="toEmployee"
                :options="employees"
                :label="isRight ? 'arabicName' : 'englishName'"
              />
            </b-form-group>
          </b-col>
          <!-- fromEmployee  -->
          <b-col md="4">
            <b-form-group>
              <g-field
                :value.sync="selectedItem.departmentId"
                :dir="isRight ? 'rtl' : 'ltr'"
                label-text="department"
                field="select"
                name="fromEmployee"
                :options="departments"
                :label="isRight ? 'arabicName' : 'englishName'"
              />
            </b-form-group>
          </b-col>
          <!-- fromDepartment  -->
          <b-col md="4">
            <b-form-group>
              <g-field
                :value.sync="selectedItem.divisionId"
                :dir="isRight ? 'rtl' : 'ltr'"
                label-text="division"
                field="select"
                name="fromDepartment"
                :options="divisions"
                :label="isRight ? 'arabicName' : 'englishName'"
              />
            </b-form-group>
          </b-col>
        </b-row>
      <!-- preview button -->
      <b-row>
        <b-col
          cols="12"
          class="d-flex justify-content-end"
        >
          <b-button
            type="submit"
            variant="relief-primary"
          >
            <feather-icon
              class="mr-50"
              icon="EyeIcon"
            />
            {{ $t("review") }}
          </b-button>
        </b-col>
      </b-row>
    </g-form>
  </b-card>
</template>

<script>
import reportMixin from '@/mixin/reportMixin';
import { reportParameters } from '@/libs/acl/Lookups'

export default {
  mixins: [reportMixin],
  props: {
      type: {
        type: Object,
        default: null,
      },
    },
  data() {
    return {
      employees: [],
      departments: [],
      divisions: [],
      selectedItem: {
        fromHireDate: this.today,
        toHireDate: this.today,
        employeeId: null,
        departmentId: null,
        divisionId: null
      },
    };
  },
  computed: {},
  beforeMount() {
    this.fiscalYearStart = this.currentYear.startDate;
    this.fiscalYearEnd = this.currentYear.endDate;
  },
  watch: {
    'selectedItem.employeeId'(employeeId) {
      this.get({ url: 'Employees', id: employeeId }).then((data) => {
          this.selectedItem.departmentId = data.departmentId
          this.selectedItem.divisionId = data.divisionId
        })
    }
  },
  mounted() {
    this.selectedItem.fromHireDate = this.getDate(this.fiscalYearStart)
    this.selectedItem.toHireDate = this.getDate(this.fiscalYearEnd)
    this.getData();
    this.getmanagement();
  },
  methods: {
    checkDate() {
      if (this.selectedItem.fromHireDate === null) {
        this.selectedItem.fromHireDate = this.getDate(this.fiscalYearStart);
      }
      if (this.selectedItem.toHireDate === null) {
        this.selectedItem.toHireDate = this.getDate(this.fiscalYearEnd);
      }
    },
    getmanagement() {
        this.get({ url: 'Divisions' }).then((data) => {
          this.divisions = data;
        });
      },
    getData() {
      this.get({ url: 'employees' })
        .then((data) => {
          this.employees = data;
        })
        .then(() => {
          this.get({ url: 'Departments' }).then((data) => {
            this.departments = data;
          });
        })
    },
    filterDataBaseOnId(source, compareTo) {
      return source.find((val) => val.id === compareTo)
    },
    preparePrametersForPrint() {
      const selectedEmployee = this.filterDataBaseOnId(this.employees, this.selectedItem.employeeId);
      const allEmployees = this.filterDataBaseOnId(reportParameters, 'allEmployees');
      this.selectedItem.employeeName = this.selectedItem.employeeId
                                     ? this.isRight ? selectedEmployee.arabicName : selectedEmployee.englishName
                                     : this.isRight ? allEmployees.arabicName : allEmployees.englishName;

      const selectedDepartment = this.filterDataBaseOnId(this.departments, this.selectedItem.departmentId);
      const allDepartments = this.filterDataBaseOnId(reportParameters, 'allDepartments');
      this.selectedItem.departmentName = this.selectedItem.departmentId
                                       ? this.isRight ? selectedDepartment.arabicName : selectedDepartment.englishName
                                       : this.isRight ? allDepartments.arabicName : allDepartments.englishName;

      const selectedDivision = this.filterDataBaseOnId(this.divisions, this.selectedItem.divisionId);
      const allDivisions = this.filterDataBaseOnId(reportParameters, 'allDivisions');
      this.selectedItem.divisionName = this.selectedItem.divisionId
                                     ? this.isRight ? selectedDivision.arabicName : selectedDivision.englishName
                                     : this.isRight ? allDivisions.arabicName : allDivisions.englishName
    },
    print() {
      this.checkDate();
      if (!this.checkForFiscalYearPerm(this.selectedItem.fromHireDate, this.selectedItem.toHireDate)) return;
      this.preparePrametersForPrint();
      this.printReport(this.isRight ? 'EmployeesList-ar' : 'EmployeesList-en', this.selectedItem);
    },
  },
};
</script>
