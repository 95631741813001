<template>
  <b-card>
    <g-form @submit="print">

      <b-row>
        <!-- employee  -->
        <b-col md="4">
          <b-form-group>
            <g-field
              :value.sync="selectedItem.employeeId"
              :dir="isRight ? 'rtl' : 'ltr'"
              label-text="employee"
              field="select"
              name="employee"
              :options="employees"
              :label="isRight ? 'arabicName' : 'englishName'"
            />
          </b-form-group>
        </b-col>

        <!-- deparment  -->
        <b-col md="4">
          <b-form-group>
            <g-field
              :value.sync="selectedItem.departmentId"
              :dir="isRight ? 'rtl' : 'ltr'"
              label-text="department"
              field="select"
              name="department"
              :options="departments"
              :label="isRight ? 'arabicName' : 'englishName'"
            />
          </b-form-group>
        </b-col>

        <!-- division  -->
        <b-col md="4">
          <b-form-group>
            <g-field
              :value.sync="selectedItem.divisionId"
              :dir="isRight ? 'rtl' : 'ltr'"
              label-text="division"
              field="select"
              name="division"
              :options="divisions"
              :label="isRight ? 'arabicName' : 'englishName'"
            />
          </b-form-group>
        </b-col>
      </b-row>

      <!-- preview button -->
      <b-row>
        <b-col
          cols="12"
          class="d-flex justify-content-end"
        >
          <b-button
            type="submit"
            variant="relief-primary"
          >
            <feather-icon
              class="mr-50"
              icon="EyeIcon"
            />
            {{ $t("review") }}
          </b-button>
        </b-col>
      </b-row>
    </g-form>
  </b-card>
</template>

<script>
import reportMixin from '@/mixin/reportMixin';
import { reportParameters } from '@/libs/acl/Lookups'

export default {
  mixins: [
    reportMixin,
  ],
  props: {
      type: {
        type: Object,
        default: null,
      },
    },
  data() {
    return {
      employees: [],
      departments: [],
      divisions: [],

      selectedItem: {
        employeeId: null,
        departmentId: null,
        divisionId: null
      },
    };
  },

  watch: {
    'selectedItem.employeeId'(employeeId) {
      this.get({ url: 'Employees', id: employeeId }).then((data) => {
          this.selectedItem.departmentId = data.departmentId
          this.selectedItem.divisionId = data.divisionId
        })
    }
  },

  mounted() {
    this.getData();
  },

  methods: {
    getData() {
        this.get({ url: 'Employees' }).then((data) => {
          this.employees = data;
        })
      .then(() => {
        this.get({ url: 'Departments' }).then((data) => {
          this.departments = data;
        })
      })
      .then(() => {
        this.get({ url: 'Divisions' }).then((data) => {
          this.divisions = data;
        })
      })
    },

    filterDataBaseOnId(source, compareTo) {
      return source.find((val) => val.id === compareTo)
    },

    preparePrametersForPrint() {
      const selectedEmployee = this.filterDataBaseOnId(this.employees, this.selectedItem.employeeId);
      const allEmployees = this.filterDataBaseOnId(reportParameters, 'allEmployees');
      this.selectedItem.employeeName = this.selectedItem.employeeId
                                     ? this.isRight ? selectedEmployee.arabicName : selectedEmployee.englishName
                                     : this.isRight ? allEmployees.arabicName : allEmployees.englishName;

      const selectedDepartment = this.filterDataBaseOnId(this.departments, this.selectedItem.departmentId);
      const allDepartments = this.filterDataBaseOnId(reportParameters, 'allDepartments');
      this.selectedItem.departmentName = this.selectedItem.departmentId
                                       ? this.isRight ? selectedDepartment.arabicName : selectedDepartment.englishName
                                       : this.isRight ? allDepartments.arabicName : allDepartments.englishName;

      const selectedDivision = this.filterDataBaseOnId(this.divisions, this.selectedItem.divisionId);
      const allDivisions = this.filterDataBaseOnId(reportParameters, 'allDivisions');
      this.selectedItem.divisionName = this.selectedItem.divisionId
                                     ? this.isRight ? selectedDivision.arabicName : selectedDivision.englishName
                                     : this.isRight ? allDivisions.arabicName : allDivisions.englishName
    },

    print() {
      this.preparePrametersForPrint();
      this.printReport(this.isRight ? 'EmployeesData-ar' : 'EmployeesData-en', this.selectedItem);
    },
  },
};
</script>
